import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { EListingCategory } from '@/enums/listing-enums';
import { ECountry } from '@/enums/location-enums';
import { useTranslation } from 'react-i18next';
import { useChatTranslation } from '@/locales/helpers/use-chat-translation';

interface ChatStepBase {
  id: string;
  question: string;
  tooltip: string;
  options?: Array<{ value: string; label: string }>;
  condition?: (data: FormData) => boolean;
}

type ChatStep = ChatStepBase & {
  type: 'text' | 'select' | 'multiselect' | 'date' | 'number' | 'boolean' | 'file' | 'budget' | 'requiredOfferType';
};

interface FormData {
  title?: string;
  category?: EListingCategory;
  country?: ECountry;
  Specifications?: string[];
  budgetAmount?: number;
  deadline?: boolean;
  deadlineDate?: string;
  files?: File[];
  requiredOfferType?: string;
  needsTraining?: boolean;
  financingOption?: string[];  // Changed from string to string[]
}

type MessageType = 'user' | 'assistant';

interface ChatMessageType {
  type: MessageType;
  content: string;
  tooltip?: string;
  id: string; // Add unique identifier
}

interface ValidationErrors {
  [key: string]: string;
}

interface InputFieldProps {
  type: ChatStep['type'];
  onSubmit: (value: any) => void;
  options?: Array<{ value: string; label: string }>;
  currentStep: number;
  formData: FormData;  // Add this prop
  chatSteps: ChatStep[]; // Add this prop
  setFormData: React.Dispatch<React.SetStateAction<FormData>>; // Add this prop
}

interface ChatBubbleProps {
  content: string;
  isUser?: boolean;
  isCurrentQuestion?: boolean; // Added this property
}

interface ReviewStepProps {
  formData: FormData;
  onConfirm: (data: FormData) => void;
  onEdit: () => void;
}

interface ListingChatFormProps {
  onComplete: (data: FormData) => void;
}

const FINANCING_OPTIONS = [
  { value: 'one_time', label: "financing.oneTime" },
  { value: 'lease', label: "financing.lease" },
  { value: 'financing', label: "financing.options" }
];

const ArrowIndicator = () => (
  <motion.div
    initial={{ opacity: 0, x: -10 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ repeat: Infinity, duration: 1, repeatType: "reverse" }}
    className="absolute left-[-40px] top-5 transform -translate-y-1/2 text-blue-500"
  >
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M13 5L20 12L13 19M4 12H20" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  </motion.div>
);

// Update the QuestionTooltip component to ensure it collapses when not current
const QuestionTooltip: React.FC<{ text: string; isCurrentQuestion: boolean; questionId: string }> = ({ 
  text, 
  isCurrentQuestion,
  questionId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const inactivityTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const pulseTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  
  // Reset all state and timeouts when component mounts or unmounts
  useEffect(() => {
    // Always start with tooltip closed
    setIsOpen(false);
    setIsInactive(false);
    
    const clearAllTimeouts = () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
        inactivityTimeoutRef.current = null;
      }
      
      if (pulseTimeoutRef.current) {
        clearTimeout(pulseTimeoutRef.current);
        pulseTimeoutRef.current = null;
      }
    };
    
    clearAllTimeouts();
    
    // Only set the inactivity timer if this is the current question
    if (isCurrentQuestion) {
      // Set a new timer for inactivity (5 seconds)
      inactivityTimeoutRef.current = setTimeout(() => {
        setIsInactive(true);
        
        // Open and close the tooltip periodically to draw attention
        setIsOpen(true);
        pulseTimeoutRef.current = setTimeout(() => {
          setIsOpen(false);
          setIsInactive(false);
        }, 4000); // Show for 4 seconds then hide
      }, 3000);
    }
    
    return clearAllTimeouts();
  }, [isCurrentQuestion, questionId]); // Re-run when questionId changes

  // Reset inactivity when user interacts with tooltip
  const handleInteraction = () => {
    setIsInactive(false);
    if (inactivityTimeoutRef.current) clearTimeout(inactivityTimeoutRef.current);
    if (pulseTimeoutRef.current) clearTimeout(pulseTimeoutRef.current);
    setIsOpen(!isOpen); // Toggle tooltip on click
  };

  // If not current question, ensure tooltip is closed
  useEffect(() => {
    if (!isCurrentQuestion) {
      setIsOpen(false);
      setIsInactive(false);
    }
  }, [isCurrentQuestion]);

  return (
    <div className="relative inline-block ml-2">
      <motion.button
        type="button"
        onClick={handleInteraction}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => !isInactive && setIsOpen(false)}
        className={`flex items-center justify-center w-6 h-6 rounded-full bg-blue-100 hover:bg-blue-200 transition-colors ${isInactive ? 'ring-2 ring-blue-300' : ''}`}
        animate={isInactive ? {
          scale: [1, 1.2, 1],
          rotate: [0, 2, -2, 0],
        } : {}}
        transition={isInactive ? {
          duration: 0.6, 
          repeat: Infinity, 
          repeatType: "reverse"
        } : {}}
      >
        <span className="text-blue-600 font-medium">?</span>
      </motion.button>
      <motion.div
        initial={false}
        animate={{
          opacity: isOpen ? 1 : 0,
          scale: isOpen ? 1 : 0.95,
        }}
        transition={{
          duration: 0.2,
          ease: "easeOut"
        }}
        className={`absolute z-50 w-64 -translate-x-1/2 left-1/2 bottom-full mb-2
                   ${isOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}
      >
        <motion.div 
          className="relative bg-white border border-gray-200 rounded-lg shadow-lg p-4 text-sm text-gray-700"
          animate={isInactive && isOpen ? {
            boxShadow: ['0 10px 15px -3px rgba(0, 0, 0, 0.1)', '0 15px 20px -2px rgba(59, 130, 246, 0.3)', '0 10px 15px -3px rgba(0, 0, 0, 0.1)'],
          } : {}}
          transition={{ duration: 1.5, repeat: isInactive && isOpen ? Infinity : 0, repeatType: "reverse" }}
        >
          {text}
          <div className="absolute -bottom-2 left-1/2 -translate-x-1/2">
            <div className="border-8 border-transparent border-t-white"></div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

// Update the function that formats category names to use translations
const formatCategoryName = (category: EListingCategory | undefined, t: any): string => {
  if (!category) return '';
  
  // Try to get the translated category name
  const translationKey = `equipment.categories.${category.toLowerCase()}`;
  const translation = t(translationKey);
  
  // If translation exists and isn't the same as the key, use it
  if (translation && translation !== translationKey) {
    return translation;
  }
  
  // Fallback to the formatted enum value
  return category.charAt(0) + category.slice(1).toLowerCase().replace(/_/g, ' ');
};

// Add the AssistantAvatar component
const AssistantAvatar = () => {
  return (
    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0 mr-2">
      <svg 
        width="16" 
        height="16" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="2" 
        className="text-blue-600"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </svg>
    </div>
  );
};

const ChatBubble: React.FC<ChatBubbleProps & { tooltip?: string; isCurrentQuestion?: boolean; questionId: string }> = ({ 
  content, 
  isUser = false, 
  tooltip,
  isCurrentQuestion = false,
  questionId 
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className={`max-w-[80%] rounded-2xl px-4 py-3 mb-4 ${
      isUser ? 'ml-auto bg-blue-600 text-white' : 'bg-gray-100'
    }`}
  >
    <div className={`${!isUser && 'text-gray-700'}`}>
      {!isUser && (
        <div className="font-medium mb-1 text-blue-600 flex items-center">
          <AssistantAvatar />
          <span>Machine-Vertical Assistant</span>
        </div>
      )}
      <div className="flex items-center flex-wrap">
        {content}
        {!isUser && tooltip && (
          <QuestionTooltip 
            key={questionId} // Use unique ID as key to force remount
            text={tooltip} 
            isCurrentQuestion={isCurrentQuestion}
            questionId={questionId}
          />
        )}
      </div>
    </div>
  </motion.div>
);

const BudgetInput: React.FC<{ onSubmit: (value: number | null) => void }> = ({ onSubmit }) => {
  const { t } = useChatTranslation();
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4">
        <button
          type="button"
          onClick={() => onSubmit(null)}
          className="w-full px-4 py-3 text-left border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
        >
          {t("buttons.flexibleBudget")}
        </button>
        <div className="flex gap-2">
          <div className="relative flex-1">
            <span className="absolute left-0 top-1/2 transform -translate-y-1/2 pl-4 text-gray-500">€</span>
            <input
              type="number"
              placeholder="Enter specific amount"
              className="w-full pl-8 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
          <button
            type="button"
            onClick={() => {
              const value = parseFloat(inputValue);
              if (value > 0) {
                onSubmit(value);
              }
            }}
            disabled={!inputValue || parseFloat(inputValue) <= 0}
            className="btn-send"
          >
            <span className="flex items-center gap-2">
              <span>{t("buttons.submitBudget")}</span>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 2L11 13"></path>
                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

// Add specification suggestions for all categories
const CATEGORY_SPEC_SUGGESTIONS = {
  [EListingCategory.CNC]: [
    { translationKey: "specSuggestions.workingArea" },
    { translationKey: "specSuggestions.spindlePower" },
    { translationKey: "specSuggestions.spindleSpeed" },
    { translationKey: "specSuggestions.controlSystem" },
    { translationKey: "specSuggestions.toolChangerCapacity" },
    { translationKey: "specSuggestions.positioningAccuracy" },
    { translationKey: "specSuggestions.maxWorkpieceWeight" },
    { translationKey: "specSuggestions.rapidTraverseSpeed" }
  ],
  [EListingCategory.WOOD_MACHINERY]: [
    { translationKey: "specSuggestions.workingWidth" },
    { translationKey: "specSuggestions.workingHeight" },
    { translationKey: "specSuggestions.feedSpeed" },
    { translationKey: "specSuggestions.motorPower" },
    { translationKey: "specSuggestions.dustExtraction" },
    { translationKey: "specSuggestions.tableDimensions" },
    { translationKey: "specSuggestions.cuttingCapacity" },
    { translationKey: "specSuggestions.numberOfSpindles" }
  ],
  [EListingCategory.WOOD_SCANNERS]: [
    { translationKey: "specSuggestions.scanningResolution" },
    { translationKey: "specSuggestions.scanningWidth" },
    { translationKey: "specSuggestions.scanningSpeed" },
    { translationKey: "specSuggestions.detectionCapabilities" },
    { translationKey: "specSuggestions.cameraType" },
    { translationKey: "specSuggestions.softwareIntegration" },
    { translationKey: "specSuggestions.defectDetection" },
    { translationKey: "specSuggestions.gradeOptimization" }
  ],
  [EListingCategory.FURNITURE_MACHINERY]: [
    { translationKey: "specSuggestions.productionCapacity" },
    { translationKey: "specSuggestions.workingDimensions" },
    { translationKey: "specSuggestions.automationLevel" },
    { translationKey: "specSuggestions.powerRequirements" },
    { translationKey: "specSuggestions.materialHandling" },
    { translationKey: "specSuggestions.controlSystem" },
    { translationKey: "specSuggestions.toolStations" },
    { translationKey: "specSuggestions.softwareCompatibility" }
  ],
  [EListingCategory.METAL_MACHINERY]: [
    { translationKey: "specSuggestions.cuttingCapacity" },
    { translationKey: "specSuggestions.workingPressure" },
    { translationKey: "specSuggestions.powerSource" },
    { translationKey: "specSuggestions.machinePower" },
    { translationKey: "specSuggestions.tableSize" },
    { translationKey: "specSuggestions.maxLoad" },
    { translationKey: "specSuggestions.processingSpeed" },
    { translationKey: "specSuggestions.positioningAccuracy" }
  ],
  [EListingCategory.SOFTWARE]: [
    { translationKey: "specSuggestions.operatingSystem" },
    { translationKey: "specSuggestions.licenseType" },
    { translationKey: "specSuggestions.usersSupported" },
    { translationKey: "specSuggestions.integration" },
    { translationKey: "specSuggestions.dataFormats" },
    { translationKey: "specSuggestions.cloudFeatures" },
    { translationKey: "specSuggestions.updates" },
    { translationKey: "specSuggestions.supportIncluded" }
  ],
  [EListingCategory.OTHER_EQUIPMENT]: [
    { translationKey: "specSuggestions.equipmentType" },
    { translationKey: "specSuggestions.powerRequirements" },
    { translationKey: "specSuggestions.dimensions" },
    { translationKey: "specSuggestions.operatingCapacity" },
    { translationKey: "specSuggestions.safetyFeatures" },
    { translationKey: "specSuggestions.certification" },
    { translationKey: "specSuggestions.installation" },
    { translationKey: "specSuggestions.maintenance" }
  ]
};

// Add SpecificationSuggestions component
const SpecificationSuggestions: React.FC<{
  onSelect: (spec: string) => void;
  category?: EListingCategory;
}> = ({ onSelect, category }) => {
  const { t } = useChatTranslation();
  
  if (!category || !CATEGORY_SPEC_SUGGESTIONS[category]) return null;

  return (
    <div className="mb-4 p-3 bg-blue-50 rounded-lg">
      <p className="text-sm text-blue-700 font-medium mb-2">
        {t("commonSpecifications")} {category.toLowerCase().replace(/_/g, ' ')}:
      </p>
      <div className="flex flex-wrap gap-2">
        {CATEGORY_SPEC_SUGGESTIONS[category].map(({ translationKey }) => {
          const displayValue = t(translationKey);
          // Extract the last part after the dot for the label (e.g., "workingArea" from "specSuggestions.workingArea")
          const baseKey = translationKey.split('.').pop() || '';

          return (
            <button
              key={translationKey}
              type="button"
              onClick={() => onSelect(displayValue)}
              className="px-3 py-1.5 text-sm bg-white text-blue-600 rounded-lg border border-blue-200 hover:bg-blue-50 hover:border-blue-300 transition-colors"
            >
              {displayValue}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const EquipmentPreferenceInput: React.FC<{ onSubmit: (value: string) => void }> = ({ onSubmit }) => {
  const { t } = useChatTranslation();
  
  return (
    <div className="flex flex-col gap-4">
      <button
        type="button"
        onClick={() => onSubmit('both')}
        className="w-full px-4 py-3 text-left border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
      >
        {t("equipment.all")}
      </button>
      <button
        type="button"
        onClick={() => onSubmit('new')}
        className="w-full px-4 py-3 text-left border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
      >
        {t("equipment.new")}
      </button>
      <button
        type="button"
        onClick={() => onSubmit('used')}
        className="w-full px-4 py-3 text-left border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
      >
        {t("equipment.used")}
      </button>
    </div>
  );
};

// Add category icons mapping
const CATEGORY_ICONS: Record<EListingCategory, string> = {
  [EListingCategory.CNC]: '🔧',
  [EListingCategory.WOOD_MACHINERY]: '🪵',
  [EListingCategory.WOOD_SCANNERS]: '🔍',
  [EListingCategory.FURNITURE_MACHINERY]: '🪑',
  [EListingCategory.METAL_MACHINERY]: '⚙️',
  [EListingCategory.SOFTWARE]: '💻',
  [EListingCategory.OTHER_EQUIPMENT]: '📦',
};

// Add CategoryGridSelector component for a more visual selection
const CategoryGridSelector: React.FC<{ 
  onSelect: (category: EListingCategory) => void 
}> = ({ onSelect }) => {
  const { t } = useChatTranslation();
  
  return (
    <div className="py-2">
      <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {Object.values(EListingCategory).map(category => (
          <motion.div
            key={category}
            onClick={() => onSelect(category)}
            className="bg-white hover:bg-blue-50 border border-slate-200 hover:border-blue-200 
                     rounded-lg p-3 cursor-pointer transition-all duration-200 
                     flex items-center space-x-2"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <span className="text-xl">{CATEGORY_ICONS[category]}</span>
            <span className="text-sm font-medium">{t(`equipment.categories.${category.toLowerCase()}`)}</span>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

// Update the InputField component to change how suggestions are handled
const InputField: React.FC<InputFieldProps> = ({ type, onSubmit, options = [], currentStep, formData, chatSteps, setFormData }) => {
  const { t } = useChatTranslation();
  const [value, setValue] = useState<string>('');
  const [specifications, setSpecifications] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(formData.financingOption || []);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const specsEndRef = useRef<HTMLDivElement>(null);
  
  // Add useEffect to scroll to the end of the specifications list when a new one is added
  useEffect(() => {
    if (specifications.length > 0) {
      specsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [specifications]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (type === 'text' && chatSteps[currentStep]?.id === 'Specifications') {
      if (value.trim()) {
        const newSpecs = [...specifications, value.trim()];
        setSpecifications(newSpecs);
        setValue('');
      }
    } else if (type === 'number' && chatSteps[currentStep]?.id === 'budgetAmount') {
      // Allow empty or 0 budget
      onSubmit(value === '' ? 0 : Number(value));
      setValue('');
    } else {
      onSubmit(value);
      setValue('');
    }
  };

  const handleAddSuggestion = (suggestionValue: string) => {
    // Instead of directly adding to specifications list, set the value in the input field
    setValue(suggestionValue);
    
    // Focus the input field
    const inputElement = document.querySelector('input[type="text"]') as HTMLInputElement;
    if (inputElement) {
      inputElement.focus();
    }
  };

  const handleFieldEdit = (field: keyof FormData, value: any) => {
    if (chatSteps[currentStep].id === field) {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  // File input handler fixed
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // Create array from FileList
      const selectedFiles = Array.from(e.target.files);
      
      // Update parent form data
      setFormData(prev => ({
        ...prev,
        files: selectedFiles
      }));
      
        
        // Format file information to include both count and names
        const fileCount = selectedFiles.length;
        const fileNames = selectedFiles.map(file => file.name);
        
        // Create a nice display message with count and filenames
        let filesMessage = t("uploadFiles.added").replace("${count}", fileCount.toString());
        filesMessage += ":\n";
        filesMessage += fileNames.map(name => `• ${name}`).join("\n");
      setValue(filesMessage);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="sticky bottom-0 bg-white border-t p-4 shadow-lg"
    >
      <motion.form
        className="max-w-3xl mx-auto relative"
        onSubmit={handleSubmit}
      >
        {type === 'text' && chatSteps[currentStep]?.id === 'Specifications' && (
          <div className="space-y-3">
            {/* Show suggestions based on selected category */}
            {formData?.category && (
              <SpecificationSuggestions 
                category={formData.category}
                onSelect={handleAddSuggestion}
              />
            )}
            
            {/* Existing specifications list */}
            {specifications.length > 0 && (
              <div className="mb-4 max-h-32 overflow-y-auto bg-gray-50 rounded-lg p-3">
                <div className="text-sm text-gray-600 mb-2 font-medium">Added specifications:</div>
                {specifications.map((spec, index) => (
                  <div key={index} className="flex items-center gap-2 text-sm text-gray-800 py-1">
                    <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                    {spec}
                  </div>
                ))}
                <div ref={specsEndRef} />
              </div>
            )}

            {/* Existing input and buttons */}
            <div className="flex gap-2">
              <div className="relative flex-1">
                <ArrowIndicator />
                <input
                  type="text"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder={t("placeholders.addSpecification")}
                  autoFocus
                />
              </div>
              <button
                type="submit"
                disabled={!value.trim()}
                className="btn-add"
              >
                <span className="flex items-center gap-1">
                  <span>{t("buttons.add")}</span>
                  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </span>
              </button>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => {
                  onSubmit(specifications);
                  setSpecifications([]);
                  setValue('');
                }}
                disabled={specifications.length === 0}
                className="btn-done"
              >
                <span className="flex items-center gap-1">
                  <span>{t("buttons.complete")}</span>
                  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        )}

        {type === 'text' && chatSteps[currentStep]?.id !== 'Specifications' && (
          <div className="flex gap-2">
            <div className="relative flex-1">
              <ArrowIndicator />
              <input
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder={chatSteps[currentStep]?.placeholder || t('placeholders.typeHere')}
                autoFocus
              />
            </div>
            <button
              type="submit"
              disabled={!value.trim()}
              className="btn-send"
            >
              <span className="flex items-center gap-2">
                <span>{t("buttons.submit")}</span>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 2L11 13"></path>
                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                </svg>
              </span>
            </button>
          </div>
        )}

        {type === 'select' && chatSteps[currentStep]?.id === 'category' && (
          <div className="space-y-4">
            <CategoryGridSelector onSelect={(category) => {
              onSubmit(category);
            }} />
          </div>
        )}

        {type === 'select' && chatSteps[currentStep]?.id !== 'category' && (
          <div className="flex gap-2">
            <div className="relative flex-1">
              <ArrowIndicator />
              <select
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="">{t('equipment.selectOption')}</option>
                {options.map((opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              disabled={!value}
              className="btn-send"
            >
              <span className="flex items-center gap-2">
                <span>{t("buttons.submit")}</span>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 2L11 13"></path>
                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                </svg>
              </span>
            </button>
          </div>
        )}

        {type === 'multiselect' && (
          <div className="space-y-4">
            <div className="relative">
              <ArrowIndicator />
              <div className="pl-12 pr-4 py-3 border border-gray-200 rounded-lg">
                {options?.map((option) => (
                  <div key={option.value} className="mb-2 last:mb-0">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 rounded"
                        value={option.value}
                        checked={selectedOptions.includes(option.value)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const optionValue = option.value;
                          
                          setSelectedOptions(prev => 
                            isChecked
                              ? [...prev, optionValue]
                              : prev.filter(value => value !== optionValue)
                          );
                        }}
                      />
                      <span className="ml-2">{t(option.label)}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => {
                  onSubmit(selectedOptions);
                }}
                className="btn-send"
              >
                <span className="flex items-center gap-2">
                  <span>{t("buttons.submit")}</span>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M22 2L11 13"></path>
                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        )}

        {type === 'boolean' && (
          <div className="flex justify-center gap-4">
            <button
              type="button"
              onClick={() => {
                setValue('true');
                onSubmit(true);
              }}
              className="btn-yes"
            >
              <span className="flex items-center gap-2">
                <span>{t("buttons.yes")}</span>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </span>
            </button>
            <button
              type="button"
              onClick={() => {
                setValue('false');
                onSubmit(false);
              }}
              className="btn-no"
            >
              <span className="flex items-center gap-2">
                <span>{t("buttons.no")}</span>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </button>
          </div>
        )}

        {type === 'number' && (
          <div className="flex gap-2">
            <div className="relative flex-1">
              <ArrowIndicator />
              <input
                type="number"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                placeholder="Enter amount..."
                autoFocus
              />
            </div>
            <button
              type="submit"
              disabled={!value}
              className="btn-send"
            >
              <span className="flex items-center gap-2">
                <span>{t("buttons.submit")}</span>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 2L11 13"></path>
                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                </svg>
              </span>
            </button>
          </div>
        )}

        {type === 'date' && (
          <div className="flex gap-2">
            <div className="relative flex-1">
              <ArrowIndicator />
              <input
                type="date"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                autoFocus
              />
            </div>
            <button
              type="submit"
              disabled={!value}
              className="btn-send"
            >
              <span className="flex items-center gap-2">
                <span>{t("buttons.submit")}</span>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 2L11 13"></path>
                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                </svg>
              </span>
            </button>
          </div>
        )}

        {type === 'file' && (
          <div className="space-y-4">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              multiple
              className="hidden"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
            />
            <div className="flex flex-col gap-4">
              <div 
                onClick={() => fileInputRef.current?.click()}
                className="cursor-pointer"
              >
                <div className="border-2 border-dashed border-slate-200 rounded-xl p-8 bg-slate-50/50 hover:bg-slate-50 transition-colors">
                  <div className="flex flex-col items-center gap-4">
                    <div className="p-3 bg-slate-100 rounded-full hover:bg-slate-200 transition-colors">
                      <svg className="w-8 h-8 text-slate-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>
                    </div>
                    <div className="text-center">
                      <p className="text-sm font-medium text-slate-600">{t("uploadFiles.title")}</p>
                      <p className="text-xs text-slate-500 mt-1">{t("uploadFiles.description")}</p>
                    </div>
                  </div>
                </div>
              </div>
              {value && (
                <div className="bg-blue-50 p-3 rounded-lg">
                  <p className="text-sm text-blue-700 font-medium">{value}</p>
                  <div className="flex justify-end mt-2">
                    <button
                      type="button"
                      onClick={() => onSubmit(formData.files || [])}
                      className="btn-send"
                    >
                      <span className="flex items-center gap-2">
                        <span>{t("buttons.submit")}</span>
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M22 2L11 13"></path>
                          <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              )}
              <div className="flex items-center gap-2 justify-center">
                <div className="h-px bg-slate-200 flex-1"></div>
                <span className="text-slate-500 text-sm">{t("uploadFiles.or")}</span>
                <div className="h-px bg-slate-200 flex-1"></div>
              </div>
              <button
                type="button"
                onClick={() => onSubmit([])}
                className="w-full py-3 text-center text-slate-600 bg-slate-100 hover:bg-slate-200 rounded-lg transition-colors"
              >
                <span className="flex items-center gap-2 justify-center">
                  <span>{t("buttons.continueWithoutFiles")}</span>
                  <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        )}

        {type === 'budget' && (
          <BudgetInput onSubmit={onSubmit} />
        )}

        {type === 'requiredOfferType' && (
          <EquipmentPreferenceInput onSubmit={onSubmit} />
        )}
      </motion.form>
    </motion.div>
  );
};

// Update the ReviewStep component to use the useChatTranslation hook
const ReviewStep: React.FC<ReviewStepProps> = ({ formData, onConfirm, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState<FormData>({
    ...formData,
    files: formData.files || [],
    Specifications: formData.Specifications || []
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [newSpec, setNewSpec] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useChatTranslation();
  
  const validateFormData = (data: FormData): boolean => {
    const errors: ValidationErrors = {};
    
    if (!data.title?.trim()) {
      errors.title = t("validation.titleRequired");
    }
    if (!data.category) {
      errors.category = t("validation.categoryRequired");
    }
    if (!data.country) {
      errors.country = t("validation.countryRequired");
    }
    if (!data.Specifications || data.Specifications.length === 0) {
      errors.Specifications = t("validation.specRequired");
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFieldEdit = (field: keyof FormData, value: any) => {
    setEditedData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setEditedData(prev => ({
      ...prev,
      files: [...(prev.files || []), ...files]
    }));
  };

  const handleRemoveFile = (index: number) => {
    setEditedData(prev => ({
      ...prev,
      files: prev.files ? [...prev.files].filter((_, i) => i !== index) : []
    }));
  };

  const handleDownloadFile = (file: File) => {
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleAddSpec = (e: React.FormEvent) => {
    e.preventDefault();
    if (newSpec.trim()) {
      setEditedData(prev => ({
        ...prev,
        Specifications: [...(prev.Specifications || []), newSpec.trim()]
      }));
      setNewSpec('');
    }
  };

  const handleRemoveSpec = (index: number) => {
    setEditedData(prev => ({
      ...prev,
      Specifications: prev.Specifications ? [...prev.Specifications].filter((_, i) => i !== index) : []
    }));
  };

  const handleSaveEdits = () => {
    if (validateFormData(editedData)) {
      onConfirm(editedData);
      setIsEditing(false);
    }
  };

  const files = editedData.files || [];
  const formDataSpecs = formData.Specifications || [];
  const formDataFiles = formData.files || [];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="sticky bottom-0 bg-white border-t shadow-lg"
    >
      <div className="max-w-3xl mx-auto max-h-[60vh] overflow-y-auto p-4">
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-blue-800 mb-3">{t("review.title")}</h3>
          <div className="space-y-3">
            {isEditing ? (
              <>
                <div className="space-y-3">
                  <div>
                    <label className="font-medium block mb-1">Equipment</label>
                    <input
                      type="text"
                      value={editedData.title}
                      onChange={(e) => handleFieldEdit('title', e.target.value)}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    />
                    {validationErrors.title && (
                      <p className="text-red-500 text-sm mt-1">{validationErrors.title}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-medium block mb-1">Category</label>
                    <select
                      value={editedData.category}
                      onChange={(e) => handleFieldEdit('category', e.target.value)}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    >
                      {Object.values(EListingCategory).map(cat => (
                        <option key={cat} value={cat}>
                          {cat.charAt(0) + cat.slice(1).toLowerCase().replace(/_/g, ' ')}
                        </option>
                      ))}
                    </select>
                    {validationErrors.category && (
                      <p className="text-red-500 text-sm mt-1">{validationErrors.category}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-medium block mb-1">Location</label>
                    <select
                      value={editedData.country}
                      onChange={(e) => handleFieldEdit('country', e.target.value)}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    >
                      {Object.values(ECountry).map(country => (
                        <option key={country} value={country}>{country}</option>
                      ))}
                    </select>
                    {validationErrors.country && (
                      <p className="text-red-500 text-sm mt-1">{validationErrors.country}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-medium block mb-1">Specifications</label>
                    <div className="space-y-2">
                      {editedData.Specifications?.map((spec, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <span className="flex-1">{spec}</span>
                          <button
                            type="button"
                            onClick={() => handleRemoveSpec(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                      <form onSubmit={handleAddSpec} className="flex gap-2">
                        <input
                          type="text"
                          value={newSpec}
                          onChange={(e) => setNewSpec(e.target.value)}
                          placeholder="Add new specification"
                          className="flex-1 p-2 border rounded focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                          type="submit"
                          className="btn-add"
                        >
                          <span className="flex items-center gap-1">
                            <span>{t("buttons.yes")}</span>
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <line x1="12" y1="5" x2="12" y2="19"></line>
                              <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                          </span>
                        </button>
                      </form>
                    </div>
                    {validationErrors.Specifications && (
                      <p className="text-red-500 text-sm mt-1">{validationErrors.Specifications}</p>
                    )}
                  </div>
                  <div>
                    <label className="font-medium block mb-1">Budget</label>
                    <input
                      type="number"
                      value={editedData.budgetAmount || 0}
                      onChange={(e) => handleFieldEdit('budgetAmount', parseFloat(e.target.value))}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  {editedData.deadline && (
                    <div>
                      <label className="font-medium block mb-1">Deadline</label>
                      <input
                        type="date"
                        value={editedData.deadlineDate}
                        onChange={(e) => handleFieldEdit('deadlineDate', e.target.value)}
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  )}
                  <div>
                    <label className="font-medium block mb-1">Files</label>
                    <div className="space-y-2">
                      {files.length > 0 && (
                        <div className="space-y-2 mb-3">
                          {files.map((file, index) => (
                            <div key={index} className="flex items-center justify-between p-2 bg-white rounded border">
                              <div className="flex items-center gap-2">
                                <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                                <span className="text-sm truncate max-w-[200px]">{file.name}</span>
                                <span className="text-xs text-gray-500">
                                  ({(file.size / 1024).toFixed(1)} KB)
                                </span>
                              </div>
                              <div className="flex gap-2">
                                <button
                                  type="button"
                                  onClick={() => handleDownloadFile(file)}
                                  className="text-blue-600 hover:text-blue-800"
                                >
                                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  onClick={() => handleRemoveFile(index)}
                                  className="text-red-500 hover:text-red-700"
                                >
                                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        multiple
                        className="hidden"
                      />
                      <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="w-full px-4 py-2 border-2 border-dashed border-blue-300 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors flex items-center justify-center gap-2"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                        </svg>
                        Add Files
                      </button>
                    </div>
                  </div>
                  <div>
                    <label className="font-medium block mb-1">Payment Method</label>
                    <div className="space-y-2">
                      {FINANCING_OPTIONS.map(option => (
                        <label key={option.value} className="flex items-center">
                          <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-600 rounded"
                            value={option.value}
                            checked={Array.isArray(editedData.financingOption) 
                              ? editedData.financingOption.includes(option.value) 
                              : false}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const optionValue = option.value;
                              
                              // Handle both string and array cases
                              let updatedOptions: string[] = [];
                              
                              if (Array.isArray(editedData.financingOption)) {
                                updatedOptions = isChecked
                                  ? [...editedData.financingOption, optionValue]
                                  : editedData.financingOption.filter(val => val !== optionValue);
                              } else {
                                updatedOptions = isChecked ? [optionValue] : [];
                              }
                              
                              handleFieldEdit('financingOption', updatedOptions);
                            }}
                          />
                          <span className="ml-2">{t(option.label)}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  {/* Training Needs */}
                  <div>
                    <label className="font-medium block mb-1">Training Required</label>
                    <div className="flex items-center gap-4">
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="needsTraining"
                          checked={editedData.needsTraining === true}
                          onChange={() => handleFieldEdit('needsTraining', true)}
                          className="form-radio text-blue-600"
                        />
                        <span className="ml-2">{t("buttons.yes")}</span>
                      </label>
                      <label className="inline-flex items-center">
                        <input
                          type="radio"
                          name="needsTraining"
                          checked={editedData.needsTraining === false}
                          onChange={() => handleFieldEdit('needsTraining', false)}
                          className="form-radio text-blue-600"
                        />
                        <span className="ml-2">{t("buttons.no")}</span>
                      </label>
                    </div>
                  </div>

                  {/* Financing Options */}
                  <div>
                    <label className="font-medium block mb-1">Payment Method</label>
                    <select
                      value={editedData.financingOption || ''}
                      onChange={(e) => handleFieldEdit('financingOption', e.target.value)}
                      className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select payment method</option>
                      {FINANCING_OPTIONS.map(option => (
                        <option key={option.value} value={option.value}>
                         {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p><span className="font-medium">Equipment:</span> {formData.title}</p>
                <p><span className="font-medium">Category:</span> {formatCategoryName(formData.category, t)}</p>
                <p><span className="font-medium">Location:</span> {formData.country}</p>
                {formDataSpecs.length > 0 && (
                  <div>
                    <p className="font-medium">Specs:</p>
                    <ul className="list-disc pl-5">
                      {formDataSpecs.map((spec, index) => (
                        <li key={index}>{spec}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <p>
                  <span className="font-medium">Equipment Preference:</span>{' '}
                  {formData.requiredOfferType === 'both' ? 'All (Both New and Used Equipment)' :
                   formData.requiredOfferType === 'new' ? 'New Equipment Only' :
                   formData.requiredOfferType === 'used' ? 'Used Equipment Only' : ''}
                </p>
                <p>
                  <span className="font-medium">Training Required:</span>{' '}
                  {formData.needsTraining ? t("buttons.yes") : t("buttons.no")}
                </p>
                <p>
                  <span className="font-medium">Payment Method:</span>{' '}
                  {Array.isArray(formData.financingOption) 
                    ? formData.financingOption.map(opt => t(FINANCING_OPTIONS.find(o => o.value === opt)?.label || '')).join(', ')
                    : t(FINANCING_OPTIONS.find(opt => opt.value === formData.financingOption)?.label) || 'Not specified'}
                </p>
                {(formData.budgetAmount ?? 0) > 0 && (
                  <p><span className="font-medium">Budget:</span> {formData.budgetAmount}</p>
                )}
                {formData.deadline && formData.deadlineDate && (
                  <p><span className="font-medium">Deadline:</span> {formData.deadlineDate}</p>
                )}
                {formDataFiles.length > 0 && (
                  <div>
                    <p className="font-medium mb-2">Files:</p>
                    <div className="space-y-2">
                      {formDataFiles.map((file, index) => (
                        <div key={index} className="flex items-center justify-between p-2 bg-white rounded border">
                          <div className="flex items-center gap-2">
                            <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                            <span className="text-sm truncate max-w-[200px]">{file.name}</span>
                            <span className="text-xs text-gray-500">
                              ({(file.size / 1024).toFixed(1)} KB)
                            </span>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleDownloadFile(file)}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                            </svg>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex justify-end gap-3 p-4 bg-white border-t">
          {isEditing ? (
            <>
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 text-slate-600 hover:bg-slate-50 rounded-lg transition-colors"
              >
                {t("buttons.back")}
              </button>
              <button
                type="button"
                onClick={handleSaveEdits}
                className="btn-send"
              >
                <span className="flex items-center gap-2">
                  <span>{t("buttons.saveChanges")}</span>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                    <polyline points="17 21 17 13 7 13 7 21"></polyline>
                    <polyline points="7 3 7 8 15 8"></polyline>
                  </svg>
                </span>
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 text-slate-600 hover:bg-slate-50 rounded-lg transition-colors"
              >
                Edit
              </button>
              <button
                type="button"
                onClick={() => onEdit()}
                className="btn-send"
              >
                <span className="flex items-center gap-2">
                  <span>Start Over</span>
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 2v6h6"></path>
                    <path d="M21 12A9 9 0 0 0 6 5.3L3 8"></path>
                    <path d="M21 22v-6h-6"></path>
                    <path d="M3 12a9 9 0 0 0 15 6.7l3-2.7"></path>
                  </svg>
                </span>
              </button>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
};

// Update the main ListingChatForm component
export const ListingChatForm: React.FC<ListingChatFormProps> = ({ onComplete }) => {
  const { i18n } = useTranslation();
  const { t } = useChatTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  // Define chatSteps here to make it accessible to all components via props
  const chatSteps: ChatStep[] = [
    {
      id: 'category',
      question: t("steps.category.question"),
      type: 'select',
      options: Object.values(EListingCategory).map(cat => ({
        value: cat,
        // Use translation for category if available, otherwise format the enum
        label: formatCategoryName(cat, t)
      })),
      tooltip: t("steps.category.tooltip")
    },
    {
      id: 'country',
      question: t("steps.country.question"),
      type: 'select',
      options: Object.values(ECountry).map(country => ({
        value: country,
        label: t(`equipment.countries.${country}`)  // Updated to use translations
      })),
      tooltip: t("steps.country.tooltip")
    },
    {
      id: 'Specifications',
      question: t("steps.specifications.question"),
      type: 'text',
      tooltip: t("steps.specifications.tooltip")
    },
    {
      id: 'requiredOfferType',
      question: t("steps.requiredOfferType.question"),
      type: 'requiredOfferType',
      tooltip: t("steps.requiredOfferType.tooltip")
    },
    {
      id: 'needsTraining',
      question: t("steps.needsTraining.question"),
      type: 'boolean',
      tooltip: t("steps.needsTraining.tooltip")
    },
    {
      id: 'financingOption',
      question: t("steps.financingOption.question"),
      type: 'multiselect',
      options: FINANCING_OPTIONS,
      tooltip: t("steps.financingOption.tooltip")
    },
    {
      id: 'budgetAmount',
      question: t("steps.budgetAmount.question"),
      type: 'budget',
      tooltip: t("steps.budgetAmount.tooltip")
    },
    {
      id: 'deadline',
      question: t("steps.deadline.question"),
      type: 'boolean',
      tooltip: t("steps.deadline.tooltip")
    },
    {
      id: 'deadlineDate',
      question: t("steps.deadlineDate.question"),
      type: 'date',
      condition: (data) => data.deadline === true,
      tooltip: t("steps.deadlineDate.tooltip")
    },
    {
      id: 'title',
      question: t("steps.title.question"),
      type: 'text',
      tooltip: t("steps.title.tooltip")
    },
    {
      id: 'files',
      question: t("steps.files.question"),
      type: 'file',
      tooltip: t("steps.files.tooltip")
    }
  ];

  const [conversation, setConversation] = useState<ChatMessageType[]>([
    { 
      type: 'assistant',
      content: t("welcome"),
      id: 'welcome-message'
    },
    { 
      type: 'assistant',
      content: chatSteps[0].question,
      tooltip: chatSteps[0].tooltip,
      id: `question-0-${Date.now()}`
    }
  ]);
  const [formData, setFormData] = useState<FormData>({});
  const [isReviewing, setIsReviewing] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  const validateData = (data: FormData): boolean => {
    const errors: ValidationErrors = {};
    if (!data.title?.trim()) {
      errors.title = t("validation.titleRequired");
    }
    if (!data.category) {
      errors.category = t("validation.categoryRequired");
    }
    if (!data.country) {
      errors.country = t("validation.countryRequired");
    }
    if (!data.Specifications || data.Specifications.length === 0) {
      errors.Specifications = t("validation.specRequired");
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Scroll effect
  useEffect(() => {
    const scrollToBottom = () => {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ 
          behavior: "smooth",
          block: "end"
        });
      }, 100);
    };
    scrollToBottom();
  }, [conversation, isReviewing]);

  // Remove LanguageSelect component and related code

  const handleAnswer = (answer: any) => {
    let displayContent = answer;
    let processedAnswer = answer;
    
    // Handle display of translated category names
    if (chatSteps[currentStep].id === 'deadline') {
      displayContent = answer ? t("buttons.yes") : t("buttons.no");
      processedAnswer = answer;
    } else if (chatSteps[currentStep].id === 'budgetAmount') {
      displayContent = answer === null ? t("buttons.flexibleBudget") : `€${answer}`;
    } else if (chatSteps[currentStep].id === 'category') {
      processedAnswer = answer;
      displayContent = formatCategoryName(answer as EListingCategory, t);
    } else if (chatSteps[currentStep].id === 'requiredOfferType') {
      switch (answer) {
        case 'both':
          displayContent = t("equipment.all");
          break;
        case 'new':
          displayContent = t("equipment.new");
          break;
        case 'used':
          displayContent = t("equipment.used");
          break;
      }
    } else if (chatSteps[currentStep].id === 'needsTraining') {
      displayContent = answer ? t("buttons.yes") : t("buttons.no");
    } else if (chatSteps[currentStep].id === 'financingOption') {
      if (Array.isArray(answer) && answer.length > 0) {
        displayContent = answer.map(option => 
          t(FINANCING_OPTIONS.find(opt => opt.value === option)?.label || '')
        ).join(', ');
      } else {
        displayContent = 'None selected';
      }
    }
    
    // Rest of the handling logic
    // ...existing code...

    // Special handling for files and other types
    if (chatSteps[currentStep].id === 'budgetAmount') {
      displayContent = answer === null ? t("buttons.flexibleBudget") : `€${answer}`;
      const nextStep = currentStep + 1;
      if (nextStep < chatSteps.length) {
        const newFormData = {
          ...formData,
          budgetAmount: answer
        };
        setFormData(newFormData);
        setCurrentStep(nextStep);
        setConversation([...conversation, {
          type: 'user' as const,
          content: displayContent
        }, {
          type: 'assistant' as const,
          content: chatSteps[nextStep].question,
          tooltip: chatSteps[nextStep].tooltip,
          id: `question-${nextStep}-${Date.now()}`
        }]);
        return;
      }
    }

    if (chatSteps[currentStep].id === 'files') {
      if (Array.isArray(answer) && answer.length === 0) {
        // If skipping files, process the final form data
        const finalFormData = {
          ...formData,
          files: []
        };
        setFormData(finalFormData);
        
        // Move directly to review without validation
        setIsReviewing(true);
        setConversation([...conversation, { 
          type: 'user' as const, 
          content: t("uploadFiles.continuingWithout"),
          id: `user-response-${Date.now()}`
        }, { 
          type: 'assistant' as const,
          content: t("reviewFinal"),
          id: `review-message-${Date.now()}`
        }]);
        return;
      }
      
      if (answer instanceof FileList || Array.isArray(answer)) {
        const fileList = Array.from(answer instanceof FileList ? answer : answer);
        
        // Format file information to include both count and names
        const fileCount = fileList.length;
        const fileNames = fileList.map(file => file.name);
        
        // Create a nice display message with count and filenames
        let filesMessage = t("uploadFiles.added").replace("${count}", fileCount.toString());
        filesMessage += ":\n";
        filesMessage += fileNames.map(name => `• ${name}`).join('\n');
        console.log(filesMessage);
        displayContent = filesMessage;
        processedAnswer = fileList;
      }
    } else if (chatSteps[currentStep].id === 'Specifications' && Array.isArray(answer)) {
      if (answer.length === 0) {
        setValidationErrors({ Specifications: t("validation.specRequired") });
        return;
      }
      displayContent = t("addedSpecifications") + '\n' + answer.map(spec => `• ${spec}`).join('\n');
    }

    // Clear validation errors when a field is filled
    if (validationErrors[chatSteps[currentStep].id]) {
      const newErrors = { ...validationErrors };
      delete newErrors[chatSteps[currentStep].id];
      setValidationErrors(newErrors);
    }

    const newFormData = {
      ...formData,
      [chatSteps[currentStep].id]: processedAnswer
    };

    // Remove the old budget specific handling since we've moved it up
    if (chatSteps[currentStep].id === 'budgetAmount' && !answer) {
      return;
    }

    // Add message to conversation
    const newConversation: ChatMessageType[] = [
      ...conversation,
      { type: 'user' as const, content: displayContent.toString(), id: `user-response-${Date.now()}` }
    ];

    setFormData(newFormData);

    // Handle budget separately to avoid state conflicts
    if (chatSteps[currentStep].id === 'budgetAmount') {
      const nextStep = currentStep + 1;
      if (nextStep < chatSteps.length) {
        setCurrentStep(nextStep);
        setConversation([...newConversation, {
          type: 'assistant' as const,
          content: chatSteps[nextStep].question,
          tooltip: chatSteps[nextStep].tooltip,
          id: `question-${nextStep}-${Date.now()}`
        }]);
      }
      return;
    }

    let nextStep = currentStep + 1;
    while (nextStep < chatSteps.length) {
      const step = chatSteps[nextStep];
      if (!step.condition || step.condition(newFormData)) {
        break;
      }
      nextStep++;
    }

    if (nextStep < chatSteps.length) {
      setCurrentStep(nextStep);
      setConversation([...newConversation, { 
        type: 'assistant' as const,
        content: chatSteps[nextStep].question,
        tooltip: chatSteps[nextStep].tooltip,
        id: `question-${nextStep}-${Date.now()}`
      }]);
    } else {
      // Only validate if we're at the end of regular steps (not skipping files)
      if (chatSteps[currentStep].id !== 'files') {
        if (validateData(newFormData)) {
          setIsReviewing(true);
          setConversation([...newConversation, { 
            type: 'assistant' as const,
            content: t("review"),
            id: `review-message-${Date.now()}`
          }]);
        } else {
          // Show validation errors in chat
          const errorMessages = Object.values(validationErrors).map(error => `• ${error}`).join('\n');
          setConversation([...newConversation, { 
            type: 'assistant' as const,
            content: `Please provide the following required information:\n${errorMessages}`,
            id: `validation-errors-${Date.now()}`
          }]);
          
          // Go back to the first missing required field
          const firstMissingField = Object.keys(validationErrors)[0];
          const stepIndex = chatSteps.findIndex(step => step.id === firstMissingField);
          if (stepIndex !== -1) {
            setCurrentStep(stepIndex);
            setConversation([...newConversation, { 
              type: 'assistant' as const,
              content: chatSteps[stepIndex].question,
              tooltip: chatSteps[stepIndex].tooltip,
              id: `question-${stepIndex}-${Date.now()}`
            }]);
          }
        }
      }
    }
  };

  const handleReviewConfirm = (finalFormData: FormData) => {
    const finalData: FormData = {
      ...finalFormData,
      Specifications: finalFormData.Specifications || [],
      files: finalFormData.files
    };
    onComplete(finalData);
  };

  const handleReviewEdit = () => {
    setIsReviewing(false);
    setCurrentStep(0);
    setFormData({});
    setConversation([
      { 
        type: 'assistant' as const,
        content: "Let's update your equipment request. We'll start from the beginning.",
        id: `restart-message-${Date.now()}`
      },
      { 
        type: 'assistant' as const,
        content: chatSteps[0].question,
        tooltip: chatSteps[0].tooltip,
        id: `restart-question-0-${Date.now()}`
      }
    ]);
  };

  // Initialize first question with a welcome message
  useState(() => {
    setConversation([
      { 
        type: 'assistant' as const,
        content: t("welcome"),
        id: `welcome-init-${Date.now()}`
      },
      { 
        type: 'assistant' as const,
        content: chatSteps[0].question,
        tooltip: chatSteps[0].tooltip,
        id: `init-question-0-${Date.now()}`
      }
    ]);
  });

  return (
    <div className="h-[calc(100vh-200px)] flex flex-col bg-white">
      {/* Add AI assistant header banner */}
      <div className="bg-blue-50 py-3 px-4 border-b border-blue-100">
        <div className="max-w-3xl mx-auto flex items-center gap-3">
          <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              className="text-blue-600"
            >
              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
              <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
              <line x1="12" y1="22.08" x2="12" y2="12"></line>
            </svg>
          </div>
          <div>
            <h4 className="font-medium text-blue-600">{t("aiAssistant.title")}</h4>
            <p className="text-sm text-blue-500">
              {t("aiAssistant.description")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        <div className="max-w-3xl mx-auto">
          <AnimatePresence>
            {conversation.map((msg, idx) => (
              <ChatBubble
                key={msg.id} // Use the unique message ID as key
                content={msg.content}
                isUser={msg.type === 'user'}
                tooltip={msg.tooltip}
                isCurrentQuestion={msg.type === 'assistant' && idx === conversation.length - 1}
                questionId={msg.id}
              />
            ))}
          </AnimatePresence>
          <div ref={messagesEndRef} /> {/* Scroll anchor */}
        </div>
      </div>
      {isReviewing ? (
        <ReviewStep 
          formData={formData}
          onConfirm={handleReviewConfirm}
          onEdit={handleReviewEdit}
        />
      ) : (
        currentStep < chatSteps.length && (
          <InputField
            type={chatSteps[currentStep].type}
            options={chatSteps[currentStep].options}
            onSubmit={handleAnswer}
            currentStep={currentStep}
            formData={formData}  // Add this prop
            chatSteps={chatSteps} // Pass chatSteps as prop
            setFormData={setFormData} // Pass the setFormData function
          />
        )
      )}
    </div>
  );
};