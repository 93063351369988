import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ReactQueryProvider } from "./auth/react-query-provider";
import { defaultRoutes } from "./router/routes";
import "./scss/style.scss";

const router = createBrowserRouter(defaultRoutes);

const App = () => {
  return (
    <ReactQueryProvider>
      <RouterProvider router={router} />
    </ReactQueryProvider>
  );
};

export default App;
