import React from "react";
import { TermsAndConditions } from "@/views/pages/common/terms-and-conditions";
import { AboutUs } from "@/views/pages/common/about-us";
import AnonymousLayout from "@/layout/anonymous-layout";
import { ListingChatPage } from '@/views/pages/office-user/listings/create/chat/listing-chat-page';
import { NewRequestLanding } from '@/views/pages/landing/new-request-landing';
import { HowItWorks } from '@/views/pages/landing/how-it-works';
const Page404 = React.lazy(() => import("../views/pages/page404/page404"));

type TDefaultRoutes = {
  path: string;
  element: React.ReactNode;
  children?: TDefaultRoutes;
}[];

export const defaultRoutes: TDefaultRoutes = [
  {
    path: "/",
    element: <AnonymousLayout />,
    children: [
      {
        path: "/",
        element: <NewRequestLanding />,
      },
      {
        path: "/landing/how-it-works",
        element: <HowItWorks />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/listing/create/chat",
    element: <ListingChatPage />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];
