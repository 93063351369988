import React, { useState, useEffect } from 'react';
import { useAppTranslation } from '@/locales/helpers/translation-helpers';

const languages = [
  { code: 'en', name: 'English', flag: '🇬🇧', color: 'bg-blue-500', message: 'Find the perfect equipment supplier with ease' },
  { code: 'es', name: 'Español', flag: '🇪🇸', color: 'bg-yellow-500', message: 'Encuentre el proveedor de equipos perfecto con facilidad' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪', color: 'bg-red-500', message: 'Finden Sie ganz einfach den perfekten Anlagenlieferanten' },
  { code: 'fr', name: 'Français', flag: '🇫🇷', color: 'bg-indigo-500', message: 'Trouvez facilement le fournisseur d\'équipement idéal' },
  { code: 'it', name: 'Italiano', flag: '🇮🇹', color: 'bg-green-500', message: 'Trova facilmente il fornitore di attrezzature perfetto' },
  { code: 'ru', name: 'Русский', flag: '🇷🇺', color: 'bg-purple-500', message: 'Легко найдите идеального поставщика оборудования' },
  { code: 'lt', name: 'Lietuvių', flag: '🇱🇹', color: 'bg-orange-500', message: 'Lengvai raskite tobulą įrangos tiekėją' },
];

const LanguagesSlide: React.FC = () => {
  const { t } = useAppTranslation();
  const [activeLanguage, setActiveLanguage] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveLanguage(prev => (prev + 1) % languages.length);
    }, 2000);
    
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="bg-white rounded-xl shadow-xl p-8 border border-slate-200 h-full flex flex-col">
      <div className="flex items-center justify-between mb-8">
        <h3 className="text-xl font-bold text-slate-800">{t("NewRequestLanding.langanim.support")}</h3>
        <div className="flex items-center space-x-1">
          {languages.map((lang, idx) => (
            <div 
              key={lang.code} 
              className={`w-6 h-6 rounded-full flex items-center justify-center text-xs ${
                idx === activeLanguage ? 'ring-2 ring-blue-500 scale-125 z-10 shadow-md' : 'opacity-70'
              } transition-all duration-300`}
              title={lang.name}
            >
              {lang.flag}
            </div>
          ))}
        </div>
      </div>
      
      <div className="flex-1 flex flex-col justify-center">
        <div className="relative h-64">
          {languages.map((lang, idx) => (
            <div
              key={lang.code}
              className={`absolute top-0 left-0 w-full transition-all duration-500 ease-in-out transform ${
                idx === activeLanguage 
                  ? 'opacity-100 translate-y-0 scale-100' 
                  : 'opacity-0 translate-y-8 scale-95'
              }`}
            >
              <div className={`${lang.color} p-6 rounded-xl text-white mb-6 shadow-lg transform transition-all duration-300`}>
                <div className="flex items-center justify-between mb-3">
                  <span className="text-3xl">{lang.flag}</span>
                  <span className="font-semibold">{lang.name}</span>
                </div>
                <p className="text-lg font-medium">{lang.message}</p>
              </div>
              
              <div className="flex items-start">
                <div className="w-12 h-12 rounded-full bg-slate-100 flex items-center justify-center mr-4 text-xl shadow-sm">
                  {lang.flag}
                </div>
                <div>
                  <div className="bg-slate-100 p-4 rounded-xl shadow-sm">
                    <p className="text-sm text-slate-700">
                      Our AI assistant communicates fluently in {lang.name}, making it easy to specify your equipment needs in your preferred language.
                    </p>
                  </div>
                  <div className="mt-3 ml-4 text-xs text-slate-500">
                    + 6 more languages
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="mt-8 p-4 bg-blue-50 rounded-lg">
        <h4 className="font-medium text-blue-700 mb-2">{t("NewRequestLanding.langanim.whyMatters")}</h4>
        <ul className="list-disc list-inside space-y-1 text-sm text-blue-600">
          <li>{t("NewRequestLanding.langanim.benefits.communicate")}</li>
          <li>{t("NewRequestLanding.langanim.benefits.specifications")}</li>
          <li>{t("NewRequestLanding.langanim.benefits.documentation")}</li>
        </ul>
      </div>
    </div>
  );
};

export default LanguagesSlide;