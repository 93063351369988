import AppFooter from "@/components/app-footer";
import { Outlet } from "react-router-dom";
import { LayoutPageWrapper } from "./layout-page-wrapper/layout-page-wrapper";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AnonymousAppSidebar from "@/components/anonymous/anonymous-sidebar";
import AnonymousAppHeader from "@/components/anonymous/anonymous-app-header";
import AnonymousHorizontalHeader from "@/components/anonymous/anonymous-horizontal-header";
import AnonymousHorizontalNav from "@/components/anonymous/anonymous-horizontal-nav";

export const AnonymousLayout = () => {
  const { i18n } = useTranslation();
  const lang = "lt";
  const hasInitializedLanguage = useRef(false);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 790px)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 790px)");
    const handleScreenChange = () => {
      setIsMobile(mediaQuery.matches);
      console.log("handleScreenChange", mediaQuery);
    };
    // Add event listener for screen size changes
    mediaQuery.addEventListener("change", handleScreenChange);
    // Initial check
    handleScreenChange();
    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleScreenChange);
    };
  }, []);

  useEffect(() => {
    // Set language only once on the initial render
    if (!hasInitializedLanguage.current) {
      console.log("Changing language automatically on init:", lang);
      i18n.changeLanguage(lang);
      hasInitializedLanguage.current = true;
    }
  }, [lang, i18n]);

  return (
    <div>
      {isMobile && <AnonymousAppSidebar />}
      <div className="wrapper d-flex flex-column min-vh-100">
        {isMobile && (
          <>
            <AnonymousAppHeader />
            <div className="body flex-grow-1">
              <LayoutPageWrapper>
                <Outlet />
              </LayoutPageWrapper>
            </div>
            <AppFooter />
          </>
        )}
        {!isMobile && (
          <>
            <AnonymousHorizontalHeader />
            <AnonymousHorizontalNav />
            <div className="w-full">
              <div className="body flex-grow-1">
                <Outlet />
              </div>
              <AppFooter />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AnonymousLayout;
