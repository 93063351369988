export enum ECountry {
  GERMANY = "GERMANY",
  FRANCE = "FRANCE",
  ITALY = "ITALY",
  SPAIN = "SPAIN",
  UK = "UK",
  NETHERLANDS = "NETHERLANDS",
  BELGIUM = "BELGIUM",
  PORTUGAL = "PORTUGAL",
  SWEDEN = "SWEDEN",
  POLAND = "POLAND",
  AUSTRIA = "AUSTRIA",
  DENMARK = "DENMARK",
  FINLAND = "FINLAND",
  IRELAND = "IRELAND",
  GREECE = "GREECE",
  CZECH_REPUBLIC = "CZECH_REPUBLIC",
  ROMANIA = "ROMANIA",
  HUNGARY = "HUNGARY",
  SLOVAKIA = "SLOVAKIA",
  BULGARIA = "BULGARIA",
  CROATIA = "CROATIA",
  SLOVENIA = "SLOVENIA",
  LITHUANIA = "LITHUANIA",
  LATVIA = "LATVIA",
  ESTONIA = "ESTONIA",
  CYPRUS = "CYPRUS",
  LUXEMBOURG = "LUXEMBOURG",
  MALTA = "MALTA",
  OTHER = "OTHER"
}
