import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";


import "simplebar-react/dist/simplebar.min.css";

import {
  CBadge,
  CNavItem,
  CNavLink,
  CNavbarNav,
} from "@coreui/react";
import {
  useAppTranslation,
  ValidI18nKey,
} from "@/locales/helpers/translation-helpers";

type TBadge = {
  color: string;
  text: string;
};

type TItem = {
  items?: TItem[];
  item?: TItem;
  component: any;
  to?: string;
  href?: string;
  badge?: TBadge;
  name: string;
  icon?: React.ReactNode;
  isListItem?: boolean;
};

export const AnonymousHorizontalNavItems = ({ items }: { items: TItem[] }) => {
  const { t } = useAppTranslation();

  const navLink = (
    name: string,
    badge?: TBadge
  ) => {
    return (
      <>
       <div className="relative inline-block">
        {!!name ? t(name as ValidI18nKey) : null}
        {badge && (
          <CBadge color={badge.color} className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 animate-pulse">
            {badge.text}
          </CBadge>
        )}
        </div>
      </>
    );
  };

  const navItem = (item: TItem, index: number) => {
    const { component, name, badge, isListItem, ...rest } = item;
    return (
        <CNavItem tabIndex={index}>
          <CNavLink {...(rest.to && { as: NavLink })} {...rest} className="px-4">
            {navLink(name, badge)}
          </CNavLink>
        </CNavItem>
    );
  };

  return (
    <CNavbarNav className="mx-auto gap-2">
      {items && items.map((item, index) => navItem(item, index))}
    </CNavbarNav>
  );
};

AnonymousHorizontalNavItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};