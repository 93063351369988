import React from 'react';
import { CButton, CCard, CCardBody, CContainer, CRow, CCol } from '@coreui/react';
import { cilArrowRight, cilEnvelopeClosed, cilClipboard, cilLightbulb } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslation } from '@/locales/helpers/translation-helpers';

export const HowItWorks: React.FC = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  // Placeholder images - to be replaced with actual images
  const processImagePlaceholder = "https://via.placeholder.com/600x400";

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-700 to-blue-900 overflow-hidden">
        <div className="absolute inset-0 bg-[url('path/to/pattern.svg')] opacity-10"></div>
        <CContainer className="py-16">
          <div className="text-center max-w-4xl mx-auto">
            <h1 className="font-bold text-4xl md:text-5xl text-white mb-6">
              {t("PageHowItWorks.hero.title")}
            </h1>
            <p className="text-blue-100 text-lg md:text-xl mb-8 max-w-3xl mx-auto">
              {t("PageHowItWorks.hero.subtitle")}
            </p>
          </div>
        </CContainer>
      </div>

      {/* Detailed Process Section */}
      <CContainer className="py-20">
        <CRow className="g-5 mb-16">
          <CCol lg={6}>
            <img 
              src={processImagePlaceholder}
              alt="Submit requirements form" 
              className="rounded-lg shadow-lg w-full h-auto"
            /> 
          </CCol>
          <CCol lg={6} className="d-flex flex-column justify-content-center">
            <div className="mb-4">
              <div className="rounded-full bg-blue-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                <span className="font-bold text-blue-700 text-xl">1</span>
              </div>
              <h2 className="text-2xl font-bold mb-3">{t("PageHowItWorks.process.step1.title")}</h2>
              <p className="text-gray-600">{t("PageHowItWorks.process.step1.description")}</p>
              <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
                <li>{t("PageHowItWorks.process.step1.bullet1")}</li>
                <li>{t("PageHowItWorks.process.step1.bullet2")}</li>
                <li>{t("PageHowItWorks.process.step1.bullet3")}</li>
              </ul>
            </div>
          </CCol>
        </CRow>

        <CRow className="g-5 mb-16 flex-column-reverse flex-lg-row">
          <CCol lg={6} className="d-flex flex-column justify-content-center">
            <div className="mb-4">
              <div className="rounded-full bg-blue-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                <span className="font-bold text-blue-700 text-xl">2</span>
              </div>
              <h2 className="text-2xl font-bold mb-3">{t("PageHowItWorks.process.step2.title")}</h2>
              <p className="text-gray-600">{t("PageHowItWorks.process.step2.description")}</p>
              <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
                <li>{t("PageHowItWorks.process.step2.bullet1")}</li>
                <li>{t("PageHowItWorks.process.step2.bullet2")}</li>
                <li>{t("PageHowItWorks.process.step2.bullet3")}</li>
              </ul>
            </div>
          </CCol>
          <CCol lg={6}>
            <img 
              src={processImagePlaceholder}
              alt="Provider email response" 
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </CCol>
        </CRow>

        <CRow className="g-5 mb-16">
          <CCol lg={6}>
            <img 
              src={processImagePlaceholder}
              alt="Email negotiations" 
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </CCol>
          <CCol lg={6} className="d-flex flex-column justify-content-center">
            <div className="mb-4">
              <div className="rounded-full bg-blue-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                <span className="font-bold text-blue-700 text-xl">3</span>
              </div>
              <h2 className="text-2xl font-bold mb-3">{t("PageHowItWorks.process.step3.title")}</h2>
              <p className="text-gray-600">{t("PageHowItWorks.process.step3.description")}</p>
              <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
                <li>{t("PageHowItWorks.process.step3.bullet1")}</li>
                <li>{t("PageHowItWorks.process.step3.bullet2")}</li>
                <li>{t("PageHowItWorks.process.step3.bullet3")}</li>
              </ul>
            </div>
          </CCol>
        </CRow>
        
        <CRow className="g-5 flex-column-reverse flex-lg-row">
          <CCol lg={6} className="d-flex flex-column justify-content-center">
            <div className="mb-4">
              <div className="rounded-full bg-blue-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                <span className="font-bold text-blue-700 text-xl">4</span>
              </div>
              <h2 className="text-2xl font-bold mb-3">{t("PageHowItWorks.process.step4.title")}</h2>
              <p className="text-gray-600">{t("PageHowItWorks.process.step4.description")}</p>
              <ul className="list-disc pl-5 mt-4 space-y-2 text-gray-600">
                <li>{t("PageHowItWorks.process.step4.bullet1")}</li>
                <li>{t("PageHowItWorks.process.step4.bullet2")}</li>
                <li>{t("PageHowItWorks.process.step4.bullet3")}</li>
              </ul>
            </div>
          </CCol>
          <CCol lg={6}>
            <img 
              src={processImagePlaceholder}
              alt="Finalizing equipment purchase" 
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </CCol>
        </CRow>
      </CContainer>

      {/* Benefits Section */}
      <div className="bg-gray-50 py-20">
        <CContainer>
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">{t("PageHowItWorks.benefits.title")}</h2>
            <p className="text-gray-600 text-lg max-w-3xl mx-auto">
              {t("PageHowItWorks.benefits.subtitle")}
            </p>
          </div>
          
          <CRow className="g-5">
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-blue-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilEnvelopeClosed} className="text-blue-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t("PageHowItWorks.benefits.familiar.title")}</h3>
                  <p className="text-gray-600">
                    {t("PageHowItWorks.benefits.familiar.description")}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
            
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-green-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilClipboard} className="text-green-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t("PageHowItWorks.benefits.structured.title")}</h3>
                  <p className="text-gray-600">
                    {t("PageHowItWorks.benefits.structured.description")}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
            
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-purple-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilLightbulb} className="text-purple-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t("PageHowItWorks.benefits.expertise.title")}</h3>
                  <p className="text-gray-600">
                    {t("PageHowItWorks.benefits.expertise.description")}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-700 to-blue-900 py-20">
        <CContainer>
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
              {t("PageHowItWorks.cta.title")}
            </h2>
            <p className="text-blue-100 text-lg mb-8">
              {t("PageHowItWorks.cta.subtitle")}
            </p>
            <CButton 
              color="light" 
              size="lg"
              className="font-semibold text-blue-700 hover:text-blue-800 shadow-lg transform transition hover:scale-105 px-8"
              onClick={() => navigate('/listing/create/chat')}
            >
              <div className="flex items-center gap-2">
                {t("PageHowItWorks.cta.button")}
                <CIcon icon={cilArrowRight} />
              </div>
            </CButton>
          </div>
        </CContainer>
      </div>
    </div>
  );
};

export default HowItWorks;