import { createRoot } from "react-dom/client";
import "core-js";
import "./index.css";

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import App from "./app";
import { CustomClerkProvider } from "./auth/custom-clerk-provider";
import { ReduxProvider } from "./store/redux-provider";
import EN from "./locales/en/translation.json";
import LT from "./locales/lt/translation.json";
import RU from "./locales/ru/translation.json";
import DE from "./locales/de/translation.json";
import ES from "./locales/es/translation.json";
import FR from "./locales/fr/translation.json";
import IT from "./locales/it/translation.json";
// Add imports for German, Spanish, French, Italian translations
// Import chat form translations
import EN_CHAT from "./locales/en/chatForm.json";
import LT_CHAT from "./locales/lt/chatForm.json";
import RU_CHAT from "./locales/ru/chatForm.json";
import DE_CHAT from "./locales/de/chatForm.json";
import ES_CHAT from "./locales/es/chatForm.json";
import FR_CHAT from "./locales/fr/chatForm.json";
import IT_CHAT from "./locales/it/chatForm.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { 
      translation: EN,
      chatForm: EN_CHAT
    },
    lt: { 
      translation: LT,
      chatForm: LT_CHAT
    },
    ru: { 
      translation: RU,
      chatForm: RU_CHAT
    },
    de: { 
      translation: DE,
      chatForm: DE_CHAT
    },
    es: { 
      translation: ES,
      chatForm: ES_CHAT
    },
    fr: { 
      translation: FR,
      chatForm: FR_CHAT
    },
    it: { 
      translation: IT,
      chatForm: IT_CHAT
    }
  },
  lng: "en",
  fallbackLng: false,
  compatibilityJSON: "v3",
  interpolation: { escapeValue: false },
});

createRoot(document.getElementById("root")!).render(
  <CustomClerkProvider>
    <ReduxProvider>
      <App />
    </ReduxProvider>
  </CustomClerkProvider>
);
