import { CContainer, CNavbar, CNavbarBrand } from "@coreui/react";
import { LocaleSelect } from "../locale/locale-select";
// Import the logo
import logo from "src/assets/images/logo.svg";

const AnonymousHorizontalHeader = () => {
  
  return (
    <CNavbar expand="lg" className="rounded-b-lg bg-white border-b">
      <CContainer className="d-flex justify-content-between align-items-center">
      <CNavbarBrand href="/" className="mx-auto">
        <img 
          src={logo} 
          alt="MachineVertical Logo" 
          className="d-inline-block align-top"
          style={{ height: '40px' }}
        />
      </CNavbarBrand>
        
        <div className="d-flex gap-2">
          <LocaleSelect />
        </div>
      </CContainer>
    </CNavbar>
  );
};

export default AnonymousHorizontalHeader;
