import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAppTranslation } from '@/locales/helpers/translation-helpers';
import { 
  cilEnvelopeClosed, 
  cilEnvelopeOpen, 
  cilPaperPlane, 
  cilPencil,
  cilCommentBubble,
  cilCheckAlt
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

const ProcessAnimation: React.FC = () => {
  const { t } = useAppTranslation();
  const [step, setStep] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  // Advance steps when animation is in view
  useEffect(() => {
    if (inView) {
      const interval = setInterval(() => {
        setStep((prevStep) => (prevStep >= 2 ? 0 : prevStep + 1));
      }, 3500); // Change step every 3.5 seconds

      return () => clearInterval(interval);
    }
    return undefined;
  }, [inView]);

  return (
    <div 
      ref={ref} 
      className="w-full h-full flex items-center justify-center bg-gradient-to-b from-blue-50 to-white p-6 rounded-xl overflow-hidden"
      style={{ minHeight: '420px' }}
    >
      <div className="w-full max-w-md relative h-[400px]">
        {/* Message Path Line */}
        <div className="absolute left-1/2 top-20 bottom-20 w-[4px] bg-blue-100 transform -translate-x-1/2 z-0">
          <div 
            className="absolute top-0 w-full bg-blue-500 transition-all duration-1000 ease-in-out"
            style={{ 
              height: `${step === 0 ? 30 : step === 1 ? 65 : 100}%`,
              opacity: inView ? 1 : 0
            }}
          ></div>
        </div>

        {/* Step 1: Submit Requirements */}
        <div 
          className={`absolute top-0 left-0 w-full p-4 transition-all duration-700 ease-in-out flex items-center ${
            step === 0 ? 'opacity-100 scale-100 z-10' : 'opacity-30 scale-95'
          }`}
        >
          <div 
            className={`w-16 h-16 rounded-full shadow-lg flex items-center justify-center mr-4 text-xl font-bold transition-all duration-500 ${
              step === 0 ? 'bg-blue-600 text-white shadow-blue-500/40 shadow-lg' : 'bg-white text-blue-600 border-2 border-blue-200'
            }`}
          >
            <CIcon icon={step === 0 ? cilPaperPlane : cilPencil} className={`${step === 0 ? 'text-white' : 'text-blue-500'} text-xl`} />
          </div>
          <div className={`bg-white p-4 rounded-lg shadow-lg flex-1 border-l-4 ${step === 0 ? 'border-blue-500' : 'border-blue-200'}`}>
            <h4 className="font-semibold text-lg text-slate-800 flex items-center">
              {t('NewRequestLanding.howItWorks.step1.title')}
              {step === 0 && <span className="ml-2 bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full">Active</span>}
            </h4>
            <p className="text-sm text-slate-600 my-1">
              {t('NewRequestLanding.howItWorks.step1.description')}
            </p>

            {step === 0 && (
              <div className="mt-3 relative">
                <div className="flex items-center">
                  <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-2">
                    <CIcon icon={cilPencil} className="text-blue-600 text-sm" />
                  </div>
                  <div className="animate-pulse flex-1">
                    <div className="h-3 w-3/4 bg-blue-50 rounded mb-2"></div>
                    <div className="h-3 w-1/2 bg-blue-50 rounded"></div>
                  </div>
                </div>
                <div className="absolute -right-2 -bottom-2 w-8 h-8 rounded-full bg-blue-600 text-white flex items-center justify-center transform animate-bounce">
                  <CIcon icon={cilPaperPlane} className="text-sm" />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Step 2: Provider Responses */}
        <div 
          className={`absolute top-1/2 right-0 transform -translate-y-1/2 w-full p-4 transition-all duration-700 ease-in-out flex items-center ${
            step === 1 ? 'opacity-100 scale-100 z-10' : 'opacity-30 scale-95'
          }`}
        >
          <div 
            className={`w-16 h-16 rounded-full shadow-lg flex items-center justify-center mr-4 text-xl font-bold transition-all duration-500 ${
              step === 1 ? 'bg-blue-600 text-white shadow-blue-500/40 shadow-lg' : 'bg-white text-blue-600 border-2 border-blue-200'
            }`}
          >
            <CIcon icon={step === 1 ? cilEnvelopeOpen : cilEnvelopeClosed} className={`${step === 1 ? 'text-white' : 'text-blue-500'} text-xl`} />
          </div>
          <div className={`bg-white p-4 rounded-lg shadow-lg flex-1 border-l-4 ${step === 1 ? 'border-blue-500' : 'border-blue-200'}`}>
            <h4 className="font-semibold text-lg text-slate-800 flex items-center">
              {t('NewRequestLanding.howItWorks.step2.title')}
              {step === 1 && <span className="ml-2 bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full">Active</span>}
            </h4>
            <p className="text-sm text-slate-600 my-1">
              {t('NewRequestLanding.howItWorks.step2.description')}
            </p>

            {step === 1 && (
              <div className="mt-3 flex items-center gap-3">
                <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center text-xs text-green-600 font-medium relative group animate-bounce">
                  <CIcon icon={cilEnvelopeOpen} className="text-green-600" />
                  <span className="absolute -top-1 -right-1 w-4 h-4 bg-green-500 rounded-full text-white text-[10px] flex items-center justify-center">1</span>
                </div>
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center text-xs text-purple-600 font-medium relative group animate-bounce" style={{ animationDelay: '0.3s' }}>
                  <CIcon icon={cilEnvelopeOpen} className="text-purple-600" />
                  <span className="absolute -top-1 -right-1 w-4 h-4 bg-purple-500 rounded-full text-white text-[10px] flex items-center justify-center">2</span>
                </div>
                <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center text-xs text-blue-600 font-medium relative group animate-bounce" style={{ animationDelay: '0.6s' }}>
                  <CIcon icon={cilEnvelopeOpen} className="text-blue-600" />
                  <span className="absolute -top-1 -right-1 w-4 h-4 bg-blue-500 rounded-full text-white text-[10px] flex items-center justify-center">3</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Step 3: Continue Discussion */}
        <div 
          className={`absolute bottom-0 left-0 w-full p-4 transition-all duration-700 ease-in-out flex items-center ${
            step === 2 ? 'opacity-100 scale-100' : 'opacity-30 scale-95'
          }`}
        >
          <div 
            className={`w-16 h-16 rounded-full shadow-lg flex items-center justify-center mr-4 text-xl font-bold transition-all duration-500 ${
              step === 2 ? 'bg-blue-600 text-white shadow-blue-500/40 shadow-lg' : 'bg-white text-blue-600 border-2 border-blue-200'
            }`}
          >
            <CIcon icon={step === 2 ? cilCommentBubble : cilCheckAlt} className={`${step === 2 ? 'text-white' : 'text-blue-500'} text-xl`} />
          </div>
          <div className={`bg-white p-4 rounded-lg shadow-lg flex-1 border-l-4 ${step === 2 ? 'border-blue-500' : 'border-blue-200'}`}>
            <h4 className="font-semibold text-lg text-slate-800 flex items-center">
              {t('NewRequestLanding.howItWorks.step3.title')}
              {step === 2 && <span className="ml-2 bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full">Active</span>}
            </h4>
            <p className="text-sm text-slate-600 my-1">
              {t('NewRequestLanding.howItWorks.step3.description')}
            </p>
            
            {step === 2 && (
              <div className="mt-3 flex items-center space-x-1">
                <div className="flex items-center bg-blue-50 rounded-full px-3 py-1">
                  <div className="flex space-x-1 mr-2">
                    <div className="w-2 h-2 bg-blue-400 rounded-full animate-pulse"></div>
                    <div className="w-2 h-2 bg-blue-400 rounded-full animate-pulse" style={{ animationDelay: '0.3s' }}></div>
                    <div className="w-2 h-2 bg-blue-400 rounded-full animate-pulse" style={{ animationDelay: '0.6s' }}></div>
                  </div>
                  <span className="text-xs text-blue-500">Typing...</span>
                </div>
                <div className="ml-2 p-1 bg-green-100 rounded-full animate-ping">
                  <CIcon icon={cilCheckAlt} className="text-green-600 text-sm" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessAnimation;