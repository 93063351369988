import { AnonymousHorizontalNavItems } from "./anonymous-horizontal-nav-items";
import { _navAnonymous } from "../../_nav";
import { CContainer, CNavbar } from "@coreui/react";

const AnonymousHorizontalNav = () => {
  const navItems = _navAnonymous;

  return (
    <CNavbar expand="lg" className="rounded-b-lg" style={{ backgroundColor: "#f7f7f7" }}>
      <CContainer fluid className="d-flex justify-content-center px-0">
        <AnonymousHorizontalNavItems items={navItems} />
      </CContainer>
    </CNavbar>
  );
};

export default AnonymousHorizontalNav;
