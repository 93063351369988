import { useNavigate } from 'react-router-dom';
import { ListingChatForm } from '@/components/forms/chat-form/listing-chat-form';
// Import logo properly
import logo from 'src/assets/images/logo.svg';

export const ListingChatPage = () => {
  const navigate = useNavigate();

  const handleComplete = (formData) => {
    localStorage.setItem('listingChatFormData', JSON.stringify(formData));
    navigate('/listing/create', { replace: true });
  };

  return (
    <div className="h-screen bg-white flex flex-col">
      <div className="px-4 py-3 border-b bg-white">
        <button
          onClick={() => navigate('/')}
          className="px-4 py-2 text-gray-600 hover:text-gray-800 flex items-center gap-2"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
          Cancel
        </button>
        <div className="flex pb-1 justify-center">
          {/* Use imported logo instead of direct path */}
          <img src={logo} alt="MachineVertical Logo" className="h-8" />
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        <ListingChatForm onComplete={handleComplete} />
      </div>
    </div>
  );
};