import React, { useState, useRef, useEffect } from 'react';
import { CButton, CCard, CCardBody, CContainer, CRow, CCol } from '@coreui/react';
import { 
  cilArrowRight, 
  cilShieldAlt, 
  cilClipboard,
  cilSpeedometer, 
  cilChatBubble, 
  cilCheckCircle, 
  cilUser 
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useNavigate } from 'react-router-dom';
import { useAppTranslation } from '@/locales/helpers/translation-helpers';

// Import components
import ProcessAnimation from '@/components/animations/ProcessAnimation';
import LanguagesAnimations from '@/components/animations/LanguagesAnimations';

export const NewRequestLanding: React.FC = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showLanguages, setShowLanguages] = useState(false);

  // Configure intersection observer to autoplay video when in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && videoRef.current) {
            videoRef.current.play().catch(error => {
              console.error('Error attempting to autoplay video:', error);
            });
          } else if (!entry.isIntersecting && videoRef.current) {
            videoRef.current.pause();
          }
        });
      },
      { threshold: 0.2 } // Start playing when 20% of the video is visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  // Toggle between form and languages every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setShowLanguages(prev => !prev);
    }, 7000);

    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="bg-white w-full">
      {/* Hero Section - Full width with gentle gradient */}
      <div className="relative overflow-hidden bg-gradient-to-br from-sky-50 to-blue-100 mb-16 w-full">
        {/* Decorative Elements */}
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="absolute -top-20 -right-20 w-96 h-96 rounded-full bg-sky-100/50"></div>
          <div className="absolute bottom-10 left-10 w-40 h-40 opacity-20" 
               style={{background: 'radial-gradient(circle, #93c5fd 1px, transparent 1px)', backgroundSize: '12px 12px'}}></div>
          <div className="absolute top-1/4 right-1/4 w-40 h-1 rounded-full rotate-45 bg-blue-200/40"></div>
          <div className="absolute top-1/2 left-1/4 w-20 h-1 rounded-full -rotate-45 bg-blue-200/40"></div>
        </div>

        <div className="max-w-[90rem] mx-auto">
          <CContainer className="pt-4 pb-12 lg:py-24 relative z-10">
            <CRow className="items-center">
              {/* Left Column - Text and CTA */}
              <CCol lg={5} className="mb-12 mb-lg-0">
                {/* Highlight Label */}
                <div className="inline-flex items-center py-1 px-3 mb-6 rounded-full bg-white/80 text-blue-700 text-sm font-medium backdrop-blur-sm">
                  <span className="mr-1">✨</span> {t('NewRequestLanding.hero.badge')}
                </div>

                <h1 className="font-bold text-4xl md:text-5xl lg:text-5xl mb-6 text-slate-800">
                  {t('NewRequestLanding.hero.title')}
                </h1>
                
                <p className="text-slate-600 text-lg mb-8 leading-relaxed">
                  {t('NewRequestLanding.hero.subtitle')}
                </p>
                
                {/* Testimonial/Social Proof */}
                <div className="mb-8 flex items-center">
                  <div className="flex -space-x-2 mr-3">
                    {/* User Avatars */}
                    <div className="w-8 h-8 rounded-full border-2 border-white bg-sky-100 flex items-center justify-center text-xs font-medium text-sky-700">JD</div>
                    <div className="w-8 h-8 rounded-full border-2 border-white bg-emerald-100 flex items-center justify-center text-xs font-medium text-emerald-700">MR</div>
                    <div className="w-8 h-8 rounded-full border-2 border-white bg-violet-100 flex items-center justify-center text-xs font-medium text-violet-700">TS</div>
                  </div>
                  <p className="text-sm text-slate-600">
                    {t("NewRequestLanding.hero.stats")}
                  </p>
                </div>
                
                {/* CTA Buttons */}
                <div className="flex flex-col sm:flex-row gap-4">
                  <CButton 
                    color="primary" 
                    size="lg"
                    className="font-medium bg-blue-600 hover:bg-blue-700 border-0 shadow-lg shadow-blue-500/20 px-6"
                    onClick={() => navigate('/listing/create/chat')}
                  >
                    <div className="flex items-center gap-2">
                      {t('NewRequestLanding.hero.createButton')}
                      <CIcon icon={cilArrowRight} />
                    </div>
                  </CButton>
                  <CButton 
                    variant="outline"
                    size="lg"
                    className="border-black text-slate-700 border-slate-300 hover:bg-slate-50"
                    onClick={() => navigate('/landing/how-it-works')}
                  >
                    {t('NewRequestLanding.hero.browseButton')}
                  </CButton>
                </div>
              </CCol>
              
              {/* Right Column - Animated Content */}
              <CCol lg={{span: 6, offset: 1}} className="relative">
                <div className="transition-all duration-500 transform">
                  <div className={`absolute w-full transition-opacity duration-500 ${showLanguages ? 'opacity-100 z-10' : 'opacity-0 z-0'}`}>
                    <LanguagesAnimations />
                  </div>
                  <div className={`transition-opacity duration-500 ${!showLanguages ? 'opacity-100 z-10' : 'opacity-0 z-0'}`}>
                    <ProcessAnimation />
                  </div>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>

      {/* Benefits Section - Updated with requested benefits */}
      <div className="bg-gray-50 py-16">
        <CContainer>
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">{t('NewRequestLanding.whyChoose.title')}</h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              {t('NewRequestLanding.whyChoose.subtitle')}
            </p>
          </div>
          
          <CRow className="g-4">
            {/* Benefit 1: Simple Request */}
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-blue-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilClipboard} className="text-blue-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t('NewRequestLanding.whyChoose.benefit1.title')}</h3>
                  <p className="text-gray-600">
                    {t('NewRequestLanding.whyChoose.benefit1.description')}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
            
            {/* Benefit 2: AI-based chat */}
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-indigo-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilChatBubble} className="text-indigo-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t('NewRequestLanding.whyChoose.benefit2.title')}</h3>
                  <p className="text-gray-600">
                    {t('NewRequestLanding.whyChoose.benefit2.description')}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
            
            {/* Benefit 3: Trusted and Approved Providers */}
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-green-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilCheckCircle} className="text-green-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t('NewRequestLanding.whyChoose.benefit3.title')}</h3>
                  <p className="text-gray-600">
                    {t('NewRequestLanding.whyChoose.benefit3.description')}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
            
            {/* Benefit 4: Secure Data */}
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-purple-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilShieldAlt} className="text-purple-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t('NewRequestLanding.whyChoose.benefit4.title')}</h3>
                  <p className="text-gray-600">
                    {t('NewRequestLanding.whyChoose.benefit4.description')}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
            
            {/* Benefit 5: Easy Status Tracking */}
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-blue-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilSpeedometer} className="text-blue-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t('NewRequestLanding.whyChoose.benefit5.title')}</h3>
                  <p className="text-gray-600">
                    {t('NewRequestLanding.whyChoose.benefit5.description')}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
            
            {/* Benefit 6: No commitments, no logins */}
            <CCol lg={4} md={6}>
              <CCard className="h-100 border-0 shadow-sm hover:shadow-md transition-shadow">
                <CCardBody className="p-5">
                  <div className="rounded-full bg-orange-100 p-3 w-14 h-14 flex items-center justify-center mb-4">
                    <CIcon icon={cilUser} className="text-orange-600 text-xl" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{t('NewRequestLanding.whyChoose.benefit6.title')}</h3>
                  <p className="text-gray-600">
                    {t('NewRequestLanding.whyChoose.benefit6.description')}
                  </p>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>

      {/* How It Works Section */}
      <div className="bg-white py-20">
        <CContainer>
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">{t('NewRequestLanding.howItWorks.title')}</h2>
            <p className="text-gray-600 text-lg max-w-3xl mx-auto">
              {t('NewRequestLanding.howItWorks.subtitle')}
            </p>
          </div>
          
          <CRow className="g-4 items-center">
            <CCol md={6}>
              <div className="rounded-lg shadow-lg overflow-hidden">
                <ProcessAnimation />
              </div>
            </CCol>
            <CCol md={6}>
              <div className="space-y-6">
                <div className="flex gap-4">
                  <div className="flex-shrink-0 rounded-full bg-blue-600 text-white w-10 h-10 flex items-center justify-center font-bold">1</div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{t('NewRequestLanding.howItWorks.step1.title')}</h3>
                    <p className="text-gray-600">{t('NewRequestLanding.howItWorks.step1.description')}</p>
                  </div>
                </div>
                
                <div className="flex gap-4">
                  <div className="flex-shrink-0 rounded-full bg-blue-600 text-white w-10 h-10 flex items-center justify-center font-bold">2</div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{t('NewRequestLanding.howItWorks.step2.title')}</h3>
                    <p className="text-gray-600">{t('NewRequestLanding.howItWorks.step2.description')}</p>
                  </div>
                </div>
                
                <div className="flex gap-4">
                  <div className="flex-shrink-0 rounded-full bg-blue-600 text-white w-10 h-10 flex items-center justify-center font-bold">3</div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{t('NewRequestLanding.howItWorks.step3.title')}</h3>
                    <p className="text-gray-600">{t('NewRequestLanding.howItWorks.step3.description')}</p>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-700 to-blue-900 py-20">
        <CContainer>
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">{t('NewRequestLanding.cta.title')}</h2>
            <p className="text-blue-100 text-lg mb-8">
              {t('NewRequestLanding.cta.subtitle')}
            </p>
            <CButton 
              color="light" 
              size="lg"
              className="font-semibold text-blue-700 hover:text-blue-800 shadow-lg transform transition hover:scale-105 px-8"
              onClick={() => navigate('/listing/create/chat')}
            >
              <div className="flex items-center gap-2">
                {t('NewRequestLanding.cta.button')}
                <CIcon icon={cilArrowRight} />
              </div>
            </CButton>
          </div>
        </CContainer>
      </div>
    </div>
  );
};

export default NewRequestLanding;