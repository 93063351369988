import { useTranslation } from "react-i18next";

/**
 * Custom hook for chat form translations that respects the website's language setting
 * @returns Translation functions for chat form content
 */
export const useChatTranslation = () => {
  const { t: translate, i18n, ...rest } = useTranslation();
  
  const t = (key: string, options?: any) => {
    // Add the ChatForm prefix to all keys
    const fullKey = `ChatForm.${key}`;
    return translate(fullKey, { ...options, ns: 'chatForm' });
  };
  
  return { t, i18n, ...rest };
};