import { CContainer, CHeader, CHeaderToggler } from "@coreui/react";
import { cilMenu } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useAppDispatch } from "@/store/hooks";
import { setSidebarShow } from "@/store/ui/slice";

const AnonymousAppHeader = () => {
  const dispatch = useAppDispatch();

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(setSidebarShow(true))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
      </CContainer>
    </CHeader>
  );
};

export default AnonymousAppHeader;
